import { defineStore } from 'pinia'
import User from '@/models/user';

import { http } from '@/services/http';

export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      users: [] as User[],
      userRoles: [] as string[]
    }
  },
  getters: {
    get: (state): User[] => state.users,
    isInRole(){
      return (role: string):Boolean =>{
        return this.userRoles.some(s => s === role);
      }
    }
  },
  actions: {
    setUserRoles(roles: string[]): void {
      this.userRoles = roles;
    },
    updateUser(user: User): void {
      const index = this.users.findIndex(u => u.uid === user.uid);
      if(index < 0)
        this.users.push(user);
      else
        this.users[index] = user;
    },
    async load(): Promise<User[]> {
      this.users = [];
      const response = await http.get<User[]>(`/user`);
      for(const user of response)
        this.users.push(new User(user));
      return this.users;
    },
    async loadByUserId(id: string): Promise<User> {
      const user = new User(await http.get<User>(`/user/${id}`));
      this.updateUser(user)
      return user;
    },
    async setUserRole(uid: string, role: string, assigned: boolean): Promise<void> {
      await http.post(`/user/${uid}/role`, { roleName: role, roleValue: assigned });
    },
    async saveUser(model: User): Promise<void> {
      const response = await http.post<User>(`/user/`, model);
      const updatedUser = new User(response);
      this.updateUser(updatedUser);
    },
  },
})
