import type ModelBase from "./modelBase";

export default class Setting implements ModelBase {
    id: number;
    settingName: string;
    settingValue: string;
    lastModifiedUser: string;
    lastModifiedDate: Date;
    
    constructor(setting: Setting) {
        this.id = setting.id;
        this.settingName = setting.settingName;
        this.settingValue = setting.settingValue;
        this.lastModifiedDate = setting.lastModifiedDate;
        this.lastModifiedUser = setting.lastModifiedUser;
    }
}
