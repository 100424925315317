<template>
    <div v-if="view === 'loginWithEmail'">
      <p>Login with Email and Password</p>
      <div class="row">
        <div class="col d-flex flex-column">
          <InputText v-model="loginModel.userEmail" placeholder="Email" ></InputText>
          <p v-for="error of y$.userEmail.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <Password class="d-flex flex-column flex-fill" v-model="loginModel.userPassword" placeholder="Password" :feedback="false" ></Password>
          <p v-for="error of y$.userPassword.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col d-flex flex-column">
          <Button @click="signinEmailPassword" label="Sign In With Email" ></Button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col d-flex flex-column">
          <Button @click="goResetPassword" link label="Reset Password" ></Button>
        </div>
      </div>
      <hr >
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <Button @click="goRegister" link label="Need to create an account?" ></Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="view === 'passwordReset'">
      <p>Reset Password</p>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center mb-2">
            <InputText v-model="loginModel.userEmail" placeholder="Email" ></InputText>
            <p v-for="error of z$.userEmail.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <Button @click="resetPassword" label="Reset Password" ></Button>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <Button @click="goLogin" label="Cancel" severity="danger" class="d-flex justify-content-center">
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="view === 'register'">
      <p>Create an Account</p>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <InputText v-model="userModel.displayName" placeholder="What should we call you?" ></InputText>
            <p v-for="error of v$.displayName.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <InputText v-model="userModel.userEmail" placeholder="Email" ></InputText>
            <p v-for="error of v$.userEmail.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column justify-content-center">
            <Password class="d-flex flex-column flex-fill" v-model="userModel.userPassword" placeholder="Password"></Password>
            <p v-for="error of v$.userPassword.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="d-flex flex-column justify-content-center">
            <Password class="d-flex flex-column flex-fill" v-model="userModel.confirmPassword" placeholder="Confirm Password"></Password>
            <p v-for="error of v$.confirmPassword.$errors" :key="error.$uid" class="text-danger">{{ error.$message }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <Button @click="register" label="Create Account" ></Button>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column flex-fill justify-content-center">
            <Button @click="goLogin" label="Use an Existing Account" class="d-flex justify-content-center">
            </Button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { toaster } from '@/services/toaster';
import { computed, reactive, ref } from 'vue';
import Button from 'primevue/button';
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { useFirebaseAuth } from 'vuefire'
import useVuelidate from '@vuelidate/core';
import { required, sameAs, email, minLength, helpers } from '@vuelidate/validators';
import { useGtag } from 'vue-gtag-next';
import { useUserStore } from '@/stores/userStore';
import type User from '@/models/user';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  type UserCredential,
} from 'firebase/auth'

const toast = toaster();
const userStore = useUserStore();
const { event, exception  } = useGtag();
const userModel = reactive({
  displayName: '',
  userEmail: '',
  userPassword: '',
  confirmPassword: '',
})
const loginModel = reactive({
  userEmail: '',
  userPassword: '',
})
const view = ref<'loginWithEmail' | 'register' | 'passwordReset'>('loginWithEmail')

const auth = useFirebaseAuth()!
const registerRules = computed(() => ({
  displayName: { 
    required: helpers.withMessage('Display name is required', required), 
  },
  userEmail: { 
    required: helpers.withMessage('Email is required',required), 
    email: helpers.withMessage('Please use a valid email address',email)
  },
  userPassword: { 
    required: helpers.withMessage('Password is required',required),
    minLength: helpers.withMessage('Please use a password of at least 8 characters',minLength(8))
  },
  confirmPassword: { 
    required: helpers.withMessage('Password confirmation is required', required), 
    sameAsPassword: helpers.withMessage('Password values do not match',sameAs(userModel.userPassword))
  }
}))
const loginRules = computed(() => ({
  userEmail: { 
    required: helpers.withMessage('Email is required',required), 
    email: helpers.withMessage('Please use a valid email address',email)
  },
  userPassword: { 
    required: helpers.withMessage('Password is required',required),
  }
}))
const resetPasswordRules = computed(() => ({
  userEmail: { 
    required: helpers.withMessage('Email is required',required), 
    email: helpers.withMessage('Please use a valid email address',email)
  }
}))
const v$ = useVuelidate(registerRules, userModel)
const y$ = useVuelidate(loginRules, loginModel);
const z$ = useVuelidate(resetPasswordRules, loginModel);

const goRegister = () => {
  view.value = 'register';
  userModel.userEmail = '';
  userModel.userPassword = '';
}
const goLogin = () => {
  view.value = 'loginWithEmail';
  userModel.userEmail = '';
  userModel.userPassword = '';
}
const register = async () => {
  if(await v$.value.$validate()){
      createUserWithEmailAndPassword(auth, userModel.userEmail, userModel.userPassword)
      .then(async (userCredential: UserCredential) => {
        updateProfile(userCredential.user, { displayName: userModel.displayName });
        const user = {
          displayName: userModel.displayName,
          email: userCredential.user.email,
          photoUrl: userCredential.user.photoURL ?? '',
          uid: userCredential.user.uid,
          creationTime: userCredential.user.metadata.creationTime == null ? new Date() : new Date(userCredential.user.metadata.creationTime),
          lastSignInTime: userCredential.user.metadata.lastSignInTime == null ? new Date() : new Date(userCredential.user.metadata.lastSignInTime)
        } as User;
        await userStore.saveUser(user);
        event('sign_up', { method: 'Email' })
      })
      .catch(reason => {
        exception({ description: reason.message, fatal: true });
        event('exception', { description: reason.message, fatal: true })
        toast.add({severity: 'error', summary: 'Create account failed!', detail: reason.message, life: 3000})
        console.error('Failed createUserWithEmailAndPassword', reason)
      });
  }
  else {
    toast.add({ summary: "Oops", severity: "error", detail: "Please correct the form and try again", life: 3000})
  }
}
const signinEmailPassword = async () => {
  if(await y$.value.$validate()){
    signInWithEmailAndPassword(auth, loginModel.userEmail, loginModel.userPassword)
    .then(async (userCredential: UserCredential) => {
        const user = {
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          photoUrl: userCredential.user.photoURL ?? '',
          uid: userCredential.user.uid,
          creationTime: userCredential.user.metadata.creationTime == null ? new Date() : new Date(userCredential.user.metadata.creationTime),
          lastSignInTime: userCredential.user.metadata.lastSignInTime == null ? new Date() : new Date(userCredential.user.metadata.lastSignInTime)
        } as User;
        await userStore.saveUser(user);
        event('sign_up', { method: 'Email' })
      })
    .catch((reason) => {
      toast.add({severity: 'error', summary: 'Login failed!', detail: `Login failed.  ${reason.message}`, life: 3000})
      console.error('Failed signinEmailPassword', reason)
      loginModel.userPassword = '';
      exception({description: reason.message, fatal: true});
      
    });
    event('login', { method: 'Email' })
  }
  else {
    toast.add({severity: 'error', summary: 'Login failed!', detail: 'Please check your credentials and try again.', life: 3000})
    loginModel.userPassword = '';
  }
}
const goResetPassword = () => view.value = 'passwordReset';
const resetPassword = async () => {
  if(await z$.value.$validate()){
    sendPasswordResetEmail(auth, loginModel.userEmail)
    .then(() => {
      toast.add({ summary: 'Ok!', detail: `Please check your email for a link to reset your password.`, severity: 'success', life: 5000 });
    }).catch((error) => {
      toast.add({ summary: 'Oops!', detail: `Please check the data you entered is correct. ${error.message}`, severity: 'error' });
      exception({description: error.message, fatal: true});
    })
  }
}

</script>

<style scoped>
.google {
  background-color:#ffffff;
  color:#757575;
}
</style>