import { createApp, reactive } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import App from './App.vue'
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import router from "./router";
import './assets/main.css'

import { initializeApp } from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import { VueFire, VueFireAuth } from 'vuefire'

import VueGtag from "vue-gtag-next";

export const app = createApp(App)

app.use(PrimeVue)
app.use(createPinia())
app.use(router)
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);

app.use(VueGtag, {
  property: {
    id: "G-ZPK780HRLH",
  },
  appName: 'Bracket Champion',
  useDebugger: true
});

app.use(VueFire, {
    firebaseApp: initializeApp(firebaseConfig),
    modules: [
      VueFireAuth(),
    ],
  })

router.isReady().then(() => {
    app.mount("#app");
})
