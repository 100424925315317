<template>
  <Dialog modal header="Welcome to Bracket Champion!" :visible="ready && !user" :style="{ width: '25rem' }" :closable="false">
    <UserLogin></UserLogin>
  </Dialog>
  <div class="container-fluid">
    <nav>
      <Menubar :model="navmenu" class="menubar">
        <template #start>
          <img :src="icon" alt="Bracket" />
        </template>
        <template #item="{ item, props, root }">
            <a v-if="(item.requiresAuth === true && user && userStore.isInRole(item.requiredRole)) ||
              item.requiresAuth === undefined || item.requiresAuth === false"
              class="flex align-items-center" :class="{'nav-menu-link-dark': !isDark, 'nav-menu-link-white' : isDark}" v-bind="props.action">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
                <i v-if="item.items?.some((c: any) => c.showInMenu)" :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
            </a>
        </template>
        <template #end>
          <div v-if="user" class="d-flex align-content-center">
            <Button 
              v-if="settingStore.allowNotifications"
              type="button" 
              class="me-2" icon="pi pi-envelope" rounded outlined 
              :badge="`${notificationStore.myNotifications.length}`" 
              @click="loadNotifications(true)" />
            <Avatar 
              id="avatar"
              :image="userImage()" 
              :label="userLabel()"
              size="large"
              :class="avatarBackground"
              shape="circle"
              @click="toggleLogoutMenu"
            />
            <Menu
              id="logoutMenu"
              ref="logoutMenu"
              :model="logoutMenuOptions"
              :popup="true"
            />
          </div>
        </template>
      </Menubar>
    </nav>
    <section class="py-3">
      <Suspense>
        <div>
            <RouterView v-if="ready" />
        </div>
        <template #fallback>
          <Card>
            <template #content>
              <Skeleton width="100%" height="5rem"></Skeleton>
            </template>
          </Card>
        </template>
      </Suspense>
    </section>
    <footer>
      <Card>
        <template #content>
          <div class="text-center">&copy; Bracket Champion.  All rights reserved.</div>
        </template>
      </Card>
    </footer>
    <Toast />
    <Sidebar v-model:visible="sidebar" position="right" v-if="settingStore.allowNotifications">
      <template #header>
        <h3>Notifications</h3>
      </template>
      <div class="d-flex flex-column justify-content-between">
        <div>
          <h4>Unread Notifications</h4>
          <Message v-for="message in notificationStore.myNotifications" :key="message.id" 
            :severity="messageSeverity(message)"
            :closable="false" 
            :icon="messageIcon(message)" 
            @close="clearMessage(message)">
            <div class="d-flex justify-content-between">
              <div>{{ message.message }}</div>
              <div>Mark Read</div>
            </div>
          </Message>
        </div>
        <Button @click="loadNotifications(null)" label="View All" ></Button>
    </div>
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import { useLeagueTypeStore } from "@/stores/leagueTypeStore";
import { ref, watch, computed, onMounted, nextTick } from "vue";
import UserLogin from './components/User/UserLogin.vue';
import Menu from "primevue/menu";
import Skeleton from 'primevue/skeleton';
import Sidebar from 'primevue/sidebar';
import Card from "primevue/card";
import Avatar from 'primevue/avatar';
import Menubar from "primevue/menubar";
import { RouterView, useRouter } from "vue-router";
import Toast from "primevue/toast";
import { useDark, useToggle } from "@vueuse/core";
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import Message from "primevue/message";
import type { Notification } from "./models/notification";
import { useNotificationStore } from '@/stores/notificationStore';
import { useSettingStore } from '@/stores/settingStore';
import { toaster } from "./services/toaster";
import { useUserStore } from "./stores/userStore";
import type User from './models/user';
import { useGtag } from "vue-gtag-next";

import {
  type UserCredential,
  getRedirectResult,
  getIdTokenResult,
  type IdTokenResult,
} from 'firebase/auth'
import { getCurrentUser, useCurrentUser, useFirebaseAuth } from 'vuefire'
import 'firebaseui/dist/firebaseui.css'

const isDark = useDark({
  selector: "#theme-css",
  attribute: "href",
  valueDark: "https://unpkg.com/primevue/resources/themes/bootstrap4-dark-blue/theme.css",
  valueLight:
    "https://unpkg.com/primevue/resources/themes/bootstrap4-light-blue/theme.css",
});
const toggleDark = useToggle(isDark);
const leagueTypeStore = useLeagueTypeStore();
const userStore = useUserStore();
const router = useRouter();
const { exception } = useGtag();
const notificationStore = useNotificationStore();
const settingStore = useSettingStore();
const toast = toaster();

const toggleIcon = computed(() => (isDark.value ? "pi pi-sun" : "pi pi-moon"));
const icon = computed(() => !isDark.value ? "/tournament-icon-dark.png" : "/tournament-icon.png");
const avatarBackground = computed(() => isDark.value ? "bg-secondary-subtle text-black" : "bg-secondary text-white")
const messageSeverity = (message: Notification) => message.severity == '' ? undefined : message.severity
const messageIcon = (message: Notification) => message.iconClass == '' ? undefined : message.iconClass

const sidebar = ref(false)

const loadNotifications = async (unread: boolean | null) => {
  await notificationStore.loadMyNotifications(unread);
  sidebar.value = true
}
const clearMessage = async (notification: Notification) => {
  await notificationStore.markRead(notification.id);
}


const auth = useFirebaseAuth()!
const user = useCurrentUser();
// display errors if any
const ready = ref(false);

const userImage = (): string => user.value?.photoURL ?? "";
const userLabel = (): string => user.value?.photoURL ? "" : user.value!.email!.charAt(0).toUpperCase();

const navmenu = ref([
  {
    label: 'Home',
    command: () => router.push('/')
  },
  {
    label: 'Admin',
    requiresAuth: true,
    requiredRole: 'admin',
    items: [{
      label: 'League Types',
      command: () => router.push('/admin/leagueTypes')
    },{
      label: 'Notifications',
      command: () => toast.add({ summary: 'Not Available', detail: 'Coming Soon!', severity: 'warn', life: 3000 }) //router.push('/admin/notifications')
    },{
      label: 'Settings',
      command: () => toast.add({ summary: 'Not Available', detail: 'Coming Soon!', severity: 'warn', life: 3000 }) //router.push('/admin/settings')
    },{
      label: 'Users',
      command: () => router.push('/admin/users')
    }]
}]);

const logoutMenu = ref();
const logoutMenuOptions = ref([
  {
    label: () => (isDark.value ? "Light Mode" : "Dark Mode"),
    icon: toggleIcon,
    command: () => {
      toggleDark();
    },
  },
  {
    label: "Logout",
    icon: "pi pi-power-off",
    command: () => {
      auth.signOut();
      user.value = null;
    },
  },
]);
const toggleLogoutMenu = (event: Event) => {
  logoutMenu.value.toggle(event);
};

// only on client side
onMounted(async () => {
  const currentUser = await getCurrentUser();
  if(currentUser){
    await notificationStore.loadMyNotifications(true);
    await getIdTokenResult(currentUser)
      .then((idTokenResult: IdTokenResult) => {
        const availableRoles = idTokenResult.claims["roles"] as string;
        userStore.setUserRoles(availableRoles?.split(",") ?? []);
      })
      .catch((error) => {
        console.error('failed getIdTokenResult',error);
        exception({ 'description': error, 'fatal': true });
      });
  }
  await leagueTypeStore.load()
  await settingStore.load();
  ready.value = true;
})
</script>

<style>
 li.p-menuitem:has(> .p-menuitem-content) {
  margin: 0rem 0.5rem 0rem 0.5rem
} 

.p-sidebar-header-content {
  width: 100%;
}
</style>

<style scoped>
.p-avatar-lg{
  width: 3rem !important;
  height: 3rem !important;
  font-size: 1.5rem !important;
}

.nav-menu-link-white {
  color: white !important;
}
.nav-menu-link-dark {
  color: black !important;
}
</style>