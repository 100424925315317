import { Notification } from '@/models/notification';
import { defineStore } from 'pinia'

import { http } from '@/services/http';

export const useNotificationStore = defineStore('notificationStore', {
  state: () => {
    return {
      notifications: [] as Notification[],
      myNotifications: [] as Notification[]
    }
  },
  getters: {
    get: (state): Notification[] => state.notifications,
  },
  actions: {
    async load(): Promise<Notification[]> {
      const results = await http.get(`/notification`);
      this.notifications = [];
      for(const result of results) {
        this.notifications.push(new Notification(result));
      }
      return this.notifications;
    },
    async save(model: Notification): Promise<Notification> {
      const notification = new Notification(await http.post<Notification>(`/notification`, model));
      const index = this.notifications.findIndex(t => t.id === model.id);
      if(index < 0)
        this.notifications.push(notification);
      else this.notifications[index] = notification;
      return notification;
    },
    async delete(model: Notification) {
      await http.delete(`/notification/${model.id}`);
      const index = this.notifications.findIndex(lt => lt.id === model.id);
      this.notifications.splice(index,1);
    },
    async loadMyNotifications(unreadOnly: boolean | null): Promise<Notification[]> {
      let query = `/notification/user`;
      if(unreadOnly !== null)
        query += `?unread=${unreadOnly}`;

      const results = await http.get(query);
      this.myNotifications = [];
      for(const result of results) {
        this.myNotifications.push(new Notification(result));
      }
      return this.myNotifications;
    },
    async markRead(notificationId: number): Promise<void> {
      const index = this.notifications.findIndex(lt => lt.id === notificationId);
      await http.delete(`/notification/user/${notificationId}`);
      this.myNotifications.splice(index, 1);
    }
  }
})
