import Setting from '@/models/setting';
import { defineStore } from 'pinia'

import { http } from '@/services/http';
import { getBoolean } from '@/services/helpers';

export const useSettingStore = defineStore('settingStore', {
  state: () => {
    return {
      settings: [] as Setting[]
    }
  },
  getters: {
    get: (state): Setting[] => state.settings,
    allowNotifications: (state): boolean => getBoolean(state.settings.find(s => s.settingName === 'AllowNotifications')?.settingValue),
  },
  actions: {
    async load(): Promise<Setting[]> {
      const results = await http.get(`/setting`);
      this.settings = [];
      for(const result of results) {
        this.settings.push(new Setting(result));
      }
      return this.settings;
    },
    async save(model: Setting): Promise<Setting> {
      const setting = new Setting(await http.post<Setting>(`/setting`, model));
      const index = this.settings.findIndex(t => t.id === model.id);
      if(index < 0)
        this.settings.push(setting);
      else this.settings[index] = setting;
      return setting;
    },
    async delete(model: Setting) {
      await http.delete(`/setting/${model.id}`);
      const index = this.settings.findIndex(lt => lt.id === model.id);
      this.settings.splice(index,1);
    }
  }
})
