import type ModelBase from "./modelBase";

export class Notification  implements ModelBase {
    id: number;
    message: string;
    severity: string;
    iconClass: string;
    lastModifiedUser: string;
    lastModifiedDate: Date;
    
    constructor(notification: Notification) {
        this.id = notification.id;
        this.message = notification.message;
        this.severity = notification.severity;
        this.iconClass = notification.iconClass;
        this.lastModifiedDate = notification.lastModifiedDate;
        this.lastModifiedUser = notification.lastModifiedUser;
    }
}

export default class UserNotification implements ModelBase {
    id: number;
    notificationId: number;
    userId: number;
    unread: boolean = true;
    lastModifiedUser: string;
    lastModifiedDate: Date;

    notification: Notification;

    constructor(userNotification: UserNotification) {
        this.id = userNotification.id;
        this.notificationId = userNotification.notificationId;
        this.userId = userNotification.userId;
        this.unread = userNotification.unread;
        this.lastModifiedDate = userNotification.lastModifiedDate;
        this.lastModifiedUser = userNotification.lastModifiedUser;

        this.notification = userNotification.notification;
    }
}
