/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
function groupBy<K,V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

function getBoolean(value: any){
  switch(value){
       case true:
       case "true":
       case "TRUE":
       case "True":
       case 1:
       case "1":
       case "on":
       case "yes":
           return true;
       default: 
           return false;
   }
}


function formatDate(value: Date | undefined,
  monthStyle: "long" | "numeric" | "short" | "narrow" | "2-digit" | undefined = "2-digit",
  dayStyle: "numeric" | "2-digit" | undefined = "2-digit",
  yearStyle: "numeric" | "2-digit" | undefined = "2-digit") {

  if (value == undefined) return "";

  const date = new Date(value);
  return new Intl.DateTimeFormat('en-US', {
      month: monthStyle,
      day: dayStyle,
      year: yearStyle
  }).format(date);
}
function formatDatetime(value: Date | undefined,
  monthStyle: "long" | "numeric" | "short" | "narrow" | "2-digit" | undefined = "2-digit",
  dayStyle: "numeric" | "2-digit" | undefined = "2-digit",
  yearStyle: "numeric" | "2-digit" | undefined = "2-digit") {
  return value != undefined ? `${formatDate(value, monthStyle, dayStyle, yearStyle)} ${new Date(value).toLocaleTimeString()}`: "";
}

export { groupBy, getBoolean, formatDate, formatDatetime }