export default class User {
    uid: string;
    email: string;
    displayName: string;
    photoUrl: string;
    phoneNumber: string;
    family_name: string;
    disabled: boolean;
    customClaims: Record<string, object>;
    lastSignInTime?: Date;
    creationTime?: Date

    constructor(user: User) {
        this.uid = user.uid;
        this.email = user.email;
        this.disabled = user.disabled;
        this.displayName = user.displayName;
        this.phoneNumber = user.phoneNumber;
        this.family_name = user.family_name;
        this.photoUrl = user.photoUrl;
        this.disabled = user.disabled;
        this.customClaims = user.customClaims;
        this.lastSignInTime = user.lastSignInTime;
        this.creationTime = user.creationTime;
    }
}
