import { createRouter as createVueRouter, createWebHashHistory, createWebHistory, type RouteLocationNormalized, type Router } from 'vue-router'
import { trackRouter } from "vue-gtag-next";

export const routes = [
  {
    path: '/',
    to: '/',
    name: 'home',
    label: 'Home',
    show: true,
    component: () => import('../views/HomeView.vue'),
    children: [{
      name: 'league',
      path: '/league/:leagueId(\\d+)',
      component: () => import('../components/Leagues/LeagueHome.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueId: Number(route.params.leagueId) })
    },
    {
      name: 'bracket',
      path: '/league/:leagueId(\\d+)/bracket/:leagueEntryId(\\d+)',
      component: () => import ('@/views/BracketView.vue'),
      props: (route: RouteLocationNormalized) => ({leagueId: Number(route.params.leagueId), leagueEntryId: Number(route.params.leagueEntryId)})
    },
    {
      name: 'printable',
      path: '/league/:leagueId(\\d+)/view/:leagueEntryId(\\d+)',
      component: () => import('@/components/Bracket/Printable/PrintableBracket.vue'),
      props: (route: RouteLocationNormalized) => ({leagueId: Number(route.params.leagueId), leagueEntryId: Number(route.params.leagueEntryId)})
    }
  ]
  },
  {
    path: '/profile',
    to: '/profile',
    name: 'profile',
    label: 'Profile',
    show: false,
    requiresAuth: true,
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/admin/leagueTypes',
    to: '/admin/leagueTypes',
    name: 'admin',
    label: 'Admin',
    show: true,
    requiresAuth: true,
    requiredRole: 'admin',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AdminView.vue'),
    children: [{
      name: 'users',
      path: '/admin/users',
      to: '/admin/users',
      component: () => import('../components/Admin/Users/UsersGrid.vue'),
    },{
        name: 'notifications',
        path: '/admin/notifications',
        to: '/admin/notifications',
        component: () => import('../components/Admin/Notifications/NotificationGrid.vue'),
    },{
      name: 'settings',
      path: '/admin/settings',
      to: '/admin/settings',
      component: () => import('../components/Admin/Settings/SettingGrid.vue'),
    },{
      name: 'leagueTypes',
      path: '/admin/leagueTypes',
      to: '/admin/leagueTypes',
      component: () => import('../components/Admin/LeagueTypes/LeagueTypeGrid.vue'),
    },{
      name: 'lineups',
      path: '/admin/:id(\\d+)/lineups',
      to: '/admin/:id(\\d+)/lineups',
      component: () => import('../components/Admin/Lineups/LineupGrid.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.id) })
    },{
      name: 'winners',
      path: '/admin/:id(\\d+)/bracket',
      to: '/admin/:id(\\d+)/bracket',
      component: () => import('../components/Admin/LeagueTypes/PerfectEntry.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.id) })
    },{
      name: 'manage leagues',
      path: '/admin/:id(\\d+)/leagues',
      to: '/admin/:id(\\d+)/leagues',
      component: () => import('../components/Admin/Leagues/ManageLeagues.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.id) })
    },{
      name: 'manage league entries',
      path: '/admin/:leagueTypeId(\\d+)/leagues/:leagueId(\\d+)',
      to: '/admin/:leagueTypeId(\\d+)/leagues/:leagueId(\\d+)',
      component: () => import('../components/Admin/Leagues/LeagueEntries.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.leagueTypeId) , leagueId: Number(route.params.leagueId) })
    },{
      name: 'admin bracket',
      path: '/admin/:leagueTypeId(\\d+)/leagues/:leagueId(\\d+)/bracket/:leagueEntryId(\\d+)',
      to: '/admin/:leagueTypeId(\\d+)/leagues/:leagueId(\\d+)/bracket/:leagueEntryId(\\d+)',
      component: () => import('../components/Admin/Leagues/UserBracket.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.leagueTypeId) , leagueId: Number(route.params.leagueId), leagueEntryId: Number(route.params.leagueEntryId) })
    },{
      name: 'edit league type',
      path: '/admin/:id(\\d+)/leagueTypes',
      to: '/admin/:id(\\d+)/leagueTypes',
      component: () => import('../components/Admin/LeagueTypes/EditLeagueType.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.id) })
    },{
      name: 'available tiebreaks',
      path: `/admin/:id(\\d+)/tiebreaks`,
      to: '/admin/:id(\\d+)/tiebreaks',
      component: () => import('@/components/Admin/Tiebreaks/TiebreakGrid.vue'),
      props: (route: RouteLocationNormalized) => ({ leagueTypeId: Number(route.params.id) })
    }],
  },
  {
    //fallback route, must be last in the list
    path: '/:pathMatch(.*)*', 
    redirect: '/'
  }
];

const router = createVueRouter({
    history: createWebHashHistory (import.meta.env.BASE_URL),
    routes: routes,
});

trackRouter(router);

export default router