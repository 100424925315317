import { defineStore } from 'pinia'
import LeagueType from '@/models/leagueType';
import Tiebreak from '@/models/tiebreak';

import { http } from '@/services/http';

export const useLeagueTypeStore = defineStore('leagueTypeStore', {
  state: () => {
    return {
      leagueTypes: [] as LeagueType[]
    }
  },
  getters: {
    get: (state): LeagueType[] => state.leagueTypes,
    getById() {
      return (id: Number): LeagueType => this.leagueTypes.find((lt) => lt.id === id)!; 
    }
  },
  actions: {
    async load(): Promise<LeagueType[]> {
      const results = await http.get<LeagueType[]>(`/leagueType`);
      if(!results) return [];
      this.leagueTypes = [];
      for(const result of results) {
        this.leagueTypes.push(new LeagueType(result));
      }
      return this.leagueTypes;
    },
    async create(model: LeagueType): Promise<LeagueType> {
      const leagueType = await http.post<LeagueType>(`/leagueType`, model);
      this.leagueTypes.push(leagueType);
      return leagueType;
    },
    async update(model: LeagueType): Promise<LeagueType> {
      const leagueType = await http.put<LeagueType>(`/leagueType/${model.id}`, model);
      const index = this.leagueTypes.findIndex(lt => lt.id === leagueType.id);
      this.leagueTypes[index] = leagueType;
      return leagueType;
    },
    async delete(model: LeagueType) {
      await http.delete(`/leagueType/${model.id}`);
      const index = this.leagueTypes.findIndex(lt => lt.id === model.id);
      this.leagueTypes.splice(index,1);
    }
  }
})
